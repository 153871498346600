import React from 'react'; 
import {ReactComponent as LoaderSvg} from '../../assets/icons/spinner.svg';

export const Loader = ({
    isLoading = false,
    isPage = false
}) => {
  
  return (
    <> {isLoading&& (
    <div className={isPage ? "loader-new loader-page" : "loader-new"}>
      <LoaderSvg />
    </div>
    )}</>
  );
};