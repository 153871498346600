import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import './core/core.css';
// Импортируем компоненты страниц
const Main = React.lazy(() => import('./pages/main'));
const Payment = React.lazy(() => import('./pages/uslugi/payment_page'));
const Uslugi = React.lazy(() => import('./pages/uslugi'));
const Usluga = React.lazy(() => import('./pages/usluga'));
const Blog = React.lazy(() => import('./pages/blog'));
const Info = React.lazy(() => import('./pages/info'));
const Login = React.lazy(() => import('./pages/account/login'));
const Account = React.lazy(() => import('./pages/account/profile'));
const Order = React.lazy(() => import('./pages/account/order'));
const App = () => {

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader isLoading isPage></Loader>}>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/uslugi/" element={<Uslugi />} />
          <Route path="/uslugi/:url/" element={<Usluga />} />
          <Route path="/uslugi/:url/payment/" element={<Payment />} />
          <Route path="/blog/" element={<Blog />} />
          <Route path="/blog/:url/" element={<Info />} />
          <Route path="/account/" element={<Account />} />
          <Route path="/account/" element={<Account />} />
          <Route path="/account/order/:id/" element={<Order />} />
          <Route path="/account/login/" element={<Login />} />
          <Route path="/podborki/:url/" element={<Uslugi />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('page_id'));
root.render(<App />);
